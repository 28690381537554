/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from '@leshen/gatsby-theme-contentful'

import {
  Billboard,
  Image,
  Price,
  List,
  ListItem,
  AvailabilityForm,
  VariableContent,
  SplitContent,
  SVGImage,
} from '@leshen/gatsby-theme-leshen'

import { Typography } from '@leshen/ui'
import { graphql, Link as GatsbyLink } from 'gatsby'

import getFullStateName from '../utils/getFullStateName'
import SeoCtaBar from '../components/SeoCtaBar'

const CityTemplate = ({ data }) => {
  const { Page_Path, State, City, Promo_Code, Meta_Description, Meta_Title } =
    data.pageInfo.edges[0].node

  const State_Name = getFullStateName(State)

  const passedData = {
    ...data,
    contentfulPage: {
      seo: {
        canonical: '',
        description: Meta_Description,
        robots: 'index,follow',
        title: Meta_Title,
        titleTemplate: '',
      },
      path: Page_Path,
      promoCode: Promo_Code,
      customBreadCrumbs: null,
      header: data.allContentfulHeader.edges[0].node,
      footer: data.allContentfulFooter.edges[0].node,
    },
  }

  const stateHeroImageData = {
    ...data.stateHeroImage?.cloudinary[0]?.gatsbyImageData,
    context: data.stateHeroImage?.cloudinary[0]?.context,
  }

  const stateHeroMobileImageData = {
    ...data.stateHeroMobileImage?.cloudinary[0]?.gatsbyImageData,
    context: data.stateHeroMobileImage?.cloudinary[0]?.context,
  }

  const splitOneDesktopData = {
    ...data.splitOneDesktop?.cloudinary[0]?.gatsbyImageData,
    context: data.splitOneDesktop?.cloudinary[0]?.context,
  }

  const splitOneMobileData = {
    ...data.splitOneMobile?.cloudinary[0]?.gatsbyImageData,
    context: data.splitOneMobile?.cloudinary[0]?.context,
  }

  return (
    <Layout
      data={passedData}
      main={
        <>
          <Billboard
            customLink={GatsbyLink}
            variant="full"
            mainContent={
              <>
                <Typography variant="h1">
                  Get CenturyLink Internet in {City}, {State_Name}
                </Typography>
                <Typography variant="h4">
                  Check your ZIP code for CenturyLink internet rates starting at
                </Typography>
                <Price
                  standalone
                  className="price embedded-entry"
                  {...data.brandyPackage?.price}
                  bullets={data.brandyPackage.bullets}
                  variant="Default"
                />
                <List>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">Unlimited data</Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">No annual contract</Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">24/7 tech support</Typography>
                    </Typography>
                  </ListItem>
                </List>
                <AvailabilityForm
                  placeholderText="Enter ZIP"
                  buttonText="See Your Plan"
                  buttonColor="primary"
                />
              </>
            }
            image={<Image data={stateHeroImageData} isImageCritical />}
            mobileImage={
              <Image data={stateHeroMobileImageData} isImageCritical />
            }
          />
          <SeoCtaBar />
          <SplitContent
            backgroundColor="Light"
            customLink={GatsbyLink}
            image={<Image data={splitOneDesktopData} isImageCritical />}
            mobileImage={<Image data={splitOneMobileData} isImageCritical />}
            mainContent={
              <>
                <Typography variant="h2">
                  {City} residents can get fast, affordable speeds with
                  CenturyLink internet
                </Typography>
                <Typography>
                  Looking for fast speeds that can accommodate almost any device
                  and web activity? CenturyLink Internet in {City} is your
                  affordable, high-speed, high-quality answer. With Fiber
                  Internet, you can connect multiple devices with virtually no
                  lag.
                </Typography>
                <List>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">
                        CenturyLink Fiber Internet in {State}
                      </Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">
                        Affordable Fiber Gigabit rates
                      </Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">
                        Dedicated 24/7 customer service
                      </Typography>
                    </Typography>
                  </ListItem>
                </List>
              </>
            }
          />
          <SplitContent
            customLink={GatsbyLink}
            image={
              <SVGImage
                svg={data?.splitTwo?.cloudinary[0].svg.code}
                presentationWidth={
                  data?.splitTwo?.cloudinary?.[0]?.svg.presentationWidth
                }
              />
            }
            mainContent={
              <>
                <Typography>
                  {`Keep your family from driving up your cell phone bill with
                  expensive overage minutes with a landline phone service
                  through CenturyLink. Home phone service isn't affected by poor
                  cell phone reception, you're unlikely ot drop a call
                  mid-conversation.`}
                </Typography>
                <Typography>
                  Some CenturyLink home phone service plans also offer
                  international calling so you can stay in touch worldwide --
                  giving you another reason to choose CenturyLink home phone
                  serices. Advanced calling features included in plan.
                </Typography>
                <List>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">Call Forwarding</Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">Caller ID</Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">3-Way Calling</Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">Last Call Return</Typography>
                    </Typography>
                  </ListItem>
                </List>
              </>
            }
          />
          <VariableContent
            backgroundColor="Light"
            mainContent={
              <>
                <Typography variant="h2">
                  Save with CenturyLink bundles in {City}, {State_Name}
                </Typography>
                <Typography>
                  {`If you're looking for a way to save money and consolidate your
                  home service bills, a CenturyLink bundle is a great option. If
                  you need phone and internet service, you can find a
                  CenturyLink bundle just for you.`}
                </Typography>
                <Typography>
                  {`When you sign up for a CenturyLink bundle, not only can you
                  save money, you'll save yourself the headache of juggling
                  multiple bills and providers. Bundle your plan today and get
                  all the home services you need in ${City}`}
                </Typography>
                <List>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">
                        CenturyLink Fiber Internet in {State}
                      </Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">
                        Affordable Fiber Gigabit rates
                      </Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography variant="p">
                        Dedicated 24/7 customer service
                      </Typography>
                    </Typography>
                  </ListItem>
                </List>
              </>
            }
            alignMainContent="center"
          />
        </>
      }
    />
  )
}

CityTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
}

export default CityTemplate

export const query = graphql`
  query CityTemplateQuery(
    $pagePath: String!
    $stateHeroImage: String!
    $stateHeroMobileImage: String!
  ) {
    site {
      ...LeshenMetadata
    }
    pageInfo: allDatasetManagerGetcenturylinkAoa(
      filter: { Page_Path: { eq: $pagePath } }
    ) {
      edges {
        node {
          Page_Path
          State
          City
          Promo_Code
          Meta_Description
          Meta_Title
        }
      }
    }
    stateHeroImage: contentfulMedia(identifier: { eq: $stateHeroImage }) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    stateHeroMobileImage: contentfulMedia(
      identifier: { eq: $stateHeroMobileImage }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    splitOneDesktop: contentfulMedia(
      contentful_id: { eq: "2ZpIoT7ZMgmn0WgUeHou31" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    splitOneMobile: contentfulMedia(
      contentful_id: { eq: "74nvPueYsh2KaoWh4wmXFh" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    splitTwo: contentfulMedia(contentful_id: { eq: "5K06vRE8D4qaWWrvMq8UPr" }) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    brandyPackage(brandy_id: { eq: "ctl-copper100" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    footerDisclaimerOne: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-allrightsreserved" }
    ) {
      symbol
      text
    }
    footerDisclaimerTwo: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-dtvcopyright" }
    ) {
      symbol
      text
    }
    allContentfulHeader(
      filter: {
        identifier: {}
        id: { eq: "dbfdc774-077c-5806-b609-4031e7f39852" }
      }
    ) {
      edges {
        node {
          id
          __typename
          identifier
          navigationPosition
          hideLocation
          navigationPosition
          headerBackgroundColor
          headerTextColor
          topBarBackgroundColor
          topBarTextColor
          navigationBackgroundColor
          navigationTextColor
          hoverBackgroundColor
          hoverTextColor
          logoLink
          borderColor
          borderHeight
          textBlockAlignment
          textBlock {
            raw
          }
          linkGroups {
            ...LeshenLinkGroup
            ...LeshenNavigationLink
          }
          topBarLinks {
            ...LeshenNavigationLink
          }
          callToActions {
            ... on ContentfulButton {
              ...LeshenButton
            }
            ... on ContentfulPhoneNumber {
              ...LeshenPhoneNumber
            }
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
        }
      }
    }
    allContentfulFooter(
      filter: { id: { eq: "80461f1a-e95e-5f0c-8476-cff3cdb7fe99" } }
    ) {
      edges {
        node {
          __typename
          id
          identifier
          linkGroups {
            ...LeshenLinkGroup
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
          disclaimers {
            ...LeshenDisclaimer
          }
          navigationBackgroundColor
          navigationTextColor
          disclaimerBackgroundColor
          disclaimerTextColor
          logoLink
          logoBackgroundColor
          logoTextColor
          logoTopBorder
          logoBottomBorder
        }
      }
    }
  }
`
